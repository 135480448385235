<template>
  <div :class="`${blocksNumber} genres-block`">
    <span
      class="genres-block__title genres-block__title--desktop"
      :class="{ 'no-text': isEmpty(titleDesktop.trim()) }"
    >
      {{ titleDesktop }}
    </span>
    <span
      class="genres-block__title genres-block__title--mobile"
      :class="{ 'no-text': isEmpty(titleMobile.trim()) }"
    >
      {{ titleMobile }}
    </span>
    <div class="wrapper">
      <div
        class="woman-block genres-sub-block"
        @mouseover="womanHovered = true"
        @mouseleave="womanHovered = false"
      >
        <GenresBlockLink
          :data="womanData"
          :hovered="womanHovered"
          @load="womanImageLoaded = true"
        />
      </div>
      <div
        class="man-block genres-sub-block"
        @mouseover="manHovered = true"
        @mouseleave="manHovered = false"
      >
        <GenresBlockLink
          :data="manData"
          :hovered="manHovered"
          @load="manImageLoaded = true"
        />
      </div>
      <div class="other-block genres-sub-block">
        <div
          class="kids-block"
          @mouseover="kidsHovered = true"
          @mouseleave="kidsHovered = false"
        >
          <GenresBlockLink
            :data="kidsData"
            :hovered="kidsHovered"
            @load="kidsImageLoaded = true"
          />
        </div>
        <div
          class="home-block"
          @mouseover="homeHovered = true"
          @mouseleave="homeHovered = false"
        >
          <GenresBlockLink
            :data="homeData"
            :hovered="homeHovered"
            @load="homeImageLoaded = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import GenresBlockLink from '~/components/Homepage/GenresBlockLink.vue';
import { SfSkeleton } from '@storefront-ui/vue';
import { isEmpty, trim } from 'lodash-es';

export default defineComponent({
  name: 'GenresBlock',
  methods: { isEmpty, trim },
  components: {
    GenresBlockLink,
    SfSkeleton,
  },
  props: {
    blockData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();
    const womanHovered = ref(false);
    const manHovered = ref(false);
    const kidsHovered = ref(false);
    const homeHovered = ref(false);

    const womanImageLoaded = ref(false);
    const manImageLoaded = ref(false);
    const kidsImageLoaded = ref(false);
    const homeImageLoaded = ref(false);
    const imagesLoaded = computed(
      () =>
        womanImageLoaded.value &&
        manImageLoaded.value &&
        kidsImageLoaded.value &&
        homeImageLoaded.value
    );

    const sanitizedBlockData = computed(() => ({
      blocksNumber: props.blockData?.layout_type || 'three',
      womanData: props.blockData?.woman
        ? {
            url: props.blockData?.woman?.link?.url || '/',
            target: props.blockData?.woman?.link?.target || '_blank',
            label: props.blockData?.woman?.link?.label || '',
            src: isDesktop
              ? props.blockData?.woman?.image?.desktop
              : props.blockData?.woman?.image?.mobile,
            alt: props.blockData?.woman?.image?.alt || 'Woman',
            title: props.blockData?.woman?.title,
          }
        : null,
      manData: props.blockData?.man
        ? {
            url: props.blockData?.man?.link?.url || '/',
            target: props.blockData?.man?.link?.target || '_blank',
            label: props.blockData?.man?.link?.label || '',
            src: isDesktop
              ? props.blockData?.man?.image?.desktop
              : props.blockData?.man?.image?.mobile,
            alt: props.blockData?.man?.image?.alt || 'Man',
            title: props.blockData?.man?.title,
          }
        : null,
      kidsData: props.blockData?.kids
        ? {
            url: props.blockData?.kids?.link?.url || '/',
            target: props.blockData?.kids?.link?.target || '_blank',
            label: props.blockData?.kids?.link?.label || '',
            src: isDesktop
              ? props.blockData?.kids?.image?.desktop
              : props.blockData?.kids?.image?.mobile,
            alt: props.blockData?.kids?.image?.alt || 'kids',
            title: props.blockData?.kids?.title,
          }
        : null,
      homeData: props.blockData?.home
        ? {
            url: props.blockData?.home?.link?.url || '/',
            target: props.blockData?.home?.link?.target || '_blank',
            label: props.blockData?.home?.link?.label || '',
            src: isDesktop
              ? props.blockData?.home?.image?.desktop
              : props.blockData?.home?.image?.mobile,
            alt: props.blockData?.home?.image?.alt || 'home',
            title: props.blockData?.home?.title,
          }
        : null,
      titleDesktop:
        props.blockData?.title?.desktop || 'Discover our collections',
      titleMobile: props.blockData?.title?.mobile || 'Discover our collections',
    }));

    return {
      womanHovered,
      manHovered,
      kidsHovered,
      homeHovered,
      ...sanitizedBlockData.value,
      imagesLoaded,
      womanImageLoaded,
      manImageLoaded,
      kidsImageLoaded,
      homeImageLoaded,
    };
  },
});
</script>

<style lang="scss">
.genres-block-skeleton {
  height: 100vh;
  width: 100vw;
}
.genres-block {
  display: flex;
  margin-bottom: 3.4375rem;
  position: relative;
  flex-direction: column;
  @include for-desktop {
    margin-bottom: 5rem;
    .wrapper {
      display: flex;
      &:hover {
        cursor: pointer;
      }
      .genres-sub-block {
        width: 33.3%;
      }
    }
  }
  &__title {
    @include mobile-h2;
    letter-spacing: var(--letter-spacing-12);
    text-align: center;
    font-size: 1.4rem;
    overflow: hidden;
    width: 100%;
    @include for-mobile {
      &--desktop {
        display: none;
      }
      &--mobile.no-text {
        margin-top: 3.4375rem;
      }
    }
    @include for-desktop {
      @include desktop-h2;
      letter-spacing: var(--letter-spacing-15);
      white-space: nowrap;
      &--mobile {
        display: none;
      }
      &--desktop.no-text {
        margin-top: 5rem;
      }
    }
  }
  .woman-block,
  .man-block,
  .kids-block,
  .home-block {
    position: relative;
    overflow: hidden;
    .block-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--c-white);
      text-transform: uppercase;
      @include mobile-h2;
      letter-spacing: var(--letter-spacing-12);
      @include for-desktop {
        @include desktop-h2;
        letter-spacing: var(--letter-spacing-15);
      }
      .hover-text {
        @include paragraph-regular;
        text-align: center;
        padding-top: 1.25rem;
      }
    }
  }
  &.three {
    .home-block {
      display: none;
    }
    .kids-block {
      height: 100%;
    }
  }

  .sf-image--wrapper {
    display: flex;
  }
  .genre-image.hovered {
    @include for-desktop {
      transition: all ease-in-out 0.4s;
      transform: scale(1.2);
      filter: brightness(80%);
    }
  }
}
</style>
